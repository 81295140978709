import React from "react"
import { FaChevronCircleRight, FaInfoCircle } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SpecialNeedsTrusts = () => (
  <Layout>
    <SEO title="Special Needs Trusts" />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-full is-8-widescreen">
            <h1 className="title is-size-1">Special Needs Trusts</h1>
            <p>
              Government programs &#8211; in the form of Supplemental Security
              Income (SSI) and Medicaid – are very important for people with
              disabilities. These programs provide cash benefits, medical
              coverage and long- term supports and services. The income level
              and financial resources of an individual with a disability, or
              family who is applying on behalf of a member with a disability,
              must not exceed a certain level in order qualify for these
              government benefits. Benefit recipients are allowed to retain only
              a total of $2,000 in assets, with some exceptions. A person with a
              disability receiving SSI, who accumulates more than $2,000 in cash
              resources, may lose SSI and, possibly, Medicaid.
            </p>
            <p>
              Government benefits provide only for the bare necessities: food,
              shelter, and clothing. These benefits amount to less than a
              federal poverty level income. Obviously, there are more things and
              activities beyond these basics that add quality to life. When
              planning for someone with special needs, this poses a problem.
              Fortunately, the government established rules allowing assets to
              be held in trust for a recipient of SSI and Medicaid, as long as
              certain requirements are followed.
            </p>
            <p>
              The trusts, called Supplemental Needs or Special Needs Trusts
              (SNTs), preserve government benefit eligibility while protecting
              assets that will meet the supplemental needs of the person with a
              disability. The SNT can pay for these additional needs. Money from
              the trust cannot be distributed directly to the person with a
              disability. Instead, it must be distributed to third parties to
              pay for goods and services to be used by the person with a
              disability.
            </p>
            <p>The following are examples of what an SNT may pay for:</p>
            <ul className="fa-ul content">
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Automobile/Van
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Accounting services
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Acupuncture / Acupressure
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Appliances (TV, VCR, stereo. Microwave, stove, refrigerator,
                washer/dryer)
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Bottled Water or water service
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Bus pass/public transportation costs
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Camera, film, recorder and tapes, development of film
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Clubs and club dues (record clubs, book clubs, health clubs,
                service clubs, zoo, Advocacy Groups, museums)
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Computer hardware, software, programs
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Internet service
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Conferences
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Courses or classes (academic or recreational) including supplies
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Curtains, blinds, drapes and the like
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Dental work not covered by Medicaid, including anesthesia.
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Down payment on home or security deposit on apartment.
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Dry cleaning and/or laundry services
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Elective surgery
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Fitness equipment
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Funeral expenses
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Furniture, home furnishings
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Gasoline and/or Maintenance for automobile
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Haircuts / Salon services
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Holiday Decorations, parties, dinner dances, holiday cards
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Home alarm and/or monitoring/response system
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Home improvements, repairs and maintenance (not covered by
                Medicaid), including tools to perform home improvements, repairs
                and maintenance by homeowner
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Home Purchase (to the extent not covered by benefits)
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                House cleaning / maid services
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Insurance (automobile, home and/or possessions)
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Legal Fees/Advocacy
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Linens and towels
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Massage
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Musical instruments (including lessons and music)
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Non-food grocery items (laundry soap, bleach, fabric softener,
                deodorant, dish soap, hand and body soap, personal hygiene
                products, paper towels, napkins, Kleenex,toilet paper, any
                household cleaning products)
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Over the counter medications (including vitamins and herbs,
                etc.)
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Personal Assistance Services not covered by Medicaid
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Pet and pet’s supplies, veterinary services
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Physician specialists if not covered by Medicaid
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Private counseling if not covered by Medicaid
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Repair services (appliance, automobile, bicycle, household,
                fitness equipment)
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Snow removal/Landscaping/Lawn Service
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Sporting goods/equipment/uniforms/team pictures
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Stationary, stamps, cards, etc.
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Storage Units
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Telephone service and equipment, including cell phone, pager,
                etc.
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Therapy (Physical, Occupational, Speech) not covered by
                Medicaid.
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Tickets to concerts or sporting events (for beneficiary and an
                accompanying companion)
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Transportation (automobile, motorcycle, bicycle, moped, gas, bus
                passes)
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Utility bills (direct TV, cable TV, electric, heating)
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                Vacation (including paying for personal assistance to accompany
                the beneficiary)
              </li>
            </ul>

            <h2 className="title is-2">How is an SNT set up?</h2>
            <p>
              The laws governing trusts are hard to understand and change often
              and from state to state. New laws have considerably tightened the
              eligibility criteria for receiving government benefits and thus
              have affected many aspects of the way SNTs are drawn up. Creating
              a SNT requires detailed planning by an attorney knowledgeable in
              special needs planning.
            </p>
            <p>SNTs must be established by one of the following: </p>
            <ul className="fa-ul content">
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                court,
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                parent,
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                grandparent,
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                legal guardian/conservator.
              </li>
            </ul>
            <p>
              If the trust is &#8220;self-settled&#8221;, the trust must be
              unchangeable with regard to the following provisions:{" "}
            </p>
            <ul className="fa-ul content">
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                the trust must only be for the benefit of the disabled
                beneficiary and
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                the trust must provide an automatic duty to repay government
                benefits upon the death of the beneficiary.
              </li>
            </ul>

            <h2 className="title is-2">Evaluating SNTs</h2>
            <p>
              The Social Security Administration and the state Medicaid
              agencies, review trusts created for individuals with disabilities
              who receive government benefits. If the individual has the legal
              authority to revoke the trust and use the principle of the trust
              to meet his or her needs for food, clothing or shelter, it will
              disqualify the individual from government benefits.
            </p>
            <h2 className="title is-2">Managing the SNT</h2>
            <p>
              Having an SNT requires a named trustee. A trustee is the manager
              of the trust and has discretion to use trust proceeds provided for
              the needs of the individual with a disability. The SNT should be
              drafted to give direction to the trustee in how to use the
              resources for the individual’s needs. The trust should be drafted
              to adjust to unforeseen circumstances, such as to allow trustees
              to be changed or removed. After the death of the individual with a
              disability, the trustee oversees the final arrangements and the
              SNT usually ends.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default SpecialNeedsTrusts
